<template>
  <div>
    <el-form ref="form" :model="formSearch" label-width="6rem" label-position="left">
      <el-row
        type="flex"
        align="top"
        justify="space-between"
        style="margin-bottom: 2%"
        class="search-wrap"
      >
        <el-col :span="16" style="display: flex">
          <el-form-item label="昵称">
            <el-input v-model="formSearch.true_name"></el-input>
          </el-form-item>
          <el-form-item label="用户名" style="margin-left:2%">
            <el-autocomplete
              class="inline-input"
              v-model="formSearch.account"
              :fetch-suggestions="querySearch"
              placeholder="请输入内容"
              @select="handleSelect"
            ></el-autocomplete>
            
          </el-form-item>
          <div style="margin-left: 2%">
            <el-button class="fill-btn" @click="search(formSearch)">搜索</el-button>
            <el-button class="un-fill-btn" @click="resetForm(formSearch)">重置</el-button>
          </div>
        </el-col>
        <el-col :span="8" style="display: flex; justify-content: flex-end">
          <el-button class="un-fill-btn" @click="addDialog = true"
            >新增用户</el-button
          >
        </el-col>
      </el-row>
    </el-form>
    <p style="color: rgb(253, 83, 83);margin-top: -16px;">
      您可以创建{{user_num}}个用户，目前已创建{{ tableData.length }}个，还可以创建{{
        user_num - tableData.length
      }}个
    </p>
    <el-table
      :data="tableData"
      style="width: calc(100% - 2px)"
      height="70vh"
      row-class-name="tableRowClassName"
      v-loading="loadingShow"
    >
      <el-table-column type="index" label="序号" align="center" width="100px">
      </el-table-column>
      <el-table-column prop="true_name" label="昵称" align="center">
      </el-table-column>
      <el-table-column prop="account" label="账号" align="center">
      </el-table-column>
      <el-table-column prop="browser_count" label="店铺数量" align="center">
      </el-table-column>
      <el-table-column prop="status" label="状态" align="center">
        <template slot-scope="scope">
          <div class="idle-status" v-if="scope.row.status === 1">正常</div>
          <div class="using-status" v-if="scope.row.status === 2">禁用</div>
        </template>
      </el-table-column>
      <el-table-column prop="create_time" label="创建时间" align="center">
      </el-table-column>
      <el-table-column prop="last_time" label="最后使用时间" align="center">
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <svg-icon icon-class="edit" @click="edit(scope.row)"></svg-icon>
          <svg-icon
            icon-class="delete"
            @click="deleteDialog = true;deleteId = scope.row.id;deleteIn = scope.row.account"
          ></svg-icon>
        </template>
      </el-table-column>
    </el-table>

    <!-- <div class="pagination-wrap">
      <el-pagination
        :background="true"
        layout="prev, pager, next"
        :total="1000"
      >
      </el-pagination>
    </div> -->
    <el-dialog :visible.sync="editDialog" width="564px">
      <el-form ref="editForm" :model="editForm" :rules="editFormRules">
        <el-form-item label="昵称" prop="true_name">
          <el-input v-model="editForm.true_name" style="width: 100%"></el-input>
        </el-form-item>
        <el-form-item label="账号" prop="account">
          <el-input v-model="editForm.account" :disabled="true" style="width: 100%"></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input v-model="editForm.password" style="width: 100%"></el-input>
        </el-form-item>
        <el-form-item label="备注">
          <el-input v-model="editForm.remark" type="textarea" rows="5" style="width: 100%"></el-input>
        </el-form-item>
         <el-form-item label="用户状态">
          <!-- 1-正常 2-禁用 -->
          <el-switch v-model="editForm.status" active-color="#13ce66" inactive-color="#ff4949" :active-value="1" :inactive-value="2">
          </el-switch>
        </el-form-item>
        <el-form-item>
            <div style="float:right;padding-top:70px">
                <el-button class="fill-btn" @click="submitEdit" :loading="editLoading">提交</el-button>
                <el-button class="un-fill-btn" @click="resetForm(editForm)">重置</el-button>
            </div>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog :visible.sync="addDialog" width="564px">
      <el-form ref="addForm" :model="addForm" :rules="addFormRules">
        <el-form-item label="昵称" prop="true_name">
          <el-input
            v-model="addForm.true_name"
            placeholder="输入昵称"
            style="width: 100%"
          ></el-input>
        </el-form-item>
        <el-form-item label="账号" prop="account">
          <el-input
            v-model="addForm.account"
            placeholder="请输入账号（电子邮箱）"
            style="width: 100%"
          ></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <label style="font-size:1.125rem;font-weight:normal">密码</label>
          <div style="width:100%">
            <el-input
                v-model="addForm.password"
                placeholder="输入密码"
                style="width: 70%"
              ></el-input>
            <span style="float: right; color: #ff6e00; cursor: pointer"
              @click="randomPass"  >点击随机生成</span
              >
          </div>
        </el-form-item>
        <el-form-item label="备注">
          <el-input v-model="addForm.remark" type="textarea" rows="5" style="width: 100%"></el-input>
        </el-form-item>
        <el-form-item label="状态">
          <!-- 1-正常 2-禁用 -->
          <el-switch v-model="addForm.status" active-color="#13ce66" inactive-color="#ff4949" :active-value="1" :inactive-value="2">
          </el-switch>
        </el-form-item>
        <!-- <el-form-item label="备注">
          <el-input
            v-model="addForm.remark"
            type="textarea"
            placeholder="输入备注"
            style="width: 100%"
            rows="5"
          ></el-input>
        </el-form-item> -->
        <el-form-item>
          <div style="float: right;padding-top:70px">
            <el-button class="fill-btn" @click="createUser" :loading="editLoading">提交</el-button>
            <el-button class="un-fill-btn" @click="resetForm(addForm)">重置</el-button>
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog :visible.sync="deleteDialog" title="删除子账号">
      <div class="freed-title">
        <i class="el-icon-info"></i>确定 删除 子账号【{{deleteIn}}】吗？
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="un-fill-btn" @click="deleteDialog = false;">取 消</el-button>
        <el-button class="fill-btn" type="primary" @click="deleteInfo">删 除</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {subaccList,subaccAdd,subaccEdit,subaccDelete,userDetail} from '@/api/index'
import {randomPassword} from '@/utils/common'
import {validPass,validEmail} from '@/utils/validate'
import Fuse from "fuse.js";
import {mapGetters} from 'vuex'
import { rsaDecrypt } from '@/utils/jsencrypt'
export default {
  data() {
    const validateName = (rule, value, callback) => {
      if (value === '') {
        callback(new Error("请输入名称"));
      } else {
        callback();
      }
    };
    const validatePassword = (rule, value, callback) => {
      if(value === ''){
        callback();
      }else{
        if(!validPass(value)){
          callback(new Error("以字母开头，长度在6~18之间,至少一个字母和一个数字"));
        }else {
          callback();
        }
      }
    };
    const validateEmail = (rule, value, callback) => {
      !validEmail(value) ? callback(new Error("请输入一个有效的电子邮箱地址")) : callback()
    };
    return {
      formSearch: {
        true_name: '',
        account: ''
      },
      loadingShow: false,
      tableData: [],
      editDialog: false,
      addDialog: false,
      addForm: {
        true_name: "",
        account: "",
        password: "",
        status: 1,
        remark: '',
      },
      editForm: {
        id: '',
        true_name: '',
        account: '',
        remark: '',
        status: null,
        password: '',
      },
      editFormRules: {
        true_name: [{ trigger: "blur", validator: validateName }],
        password: [{ trigger: "blur", validator: validatePassword }]
      },
      addFormRules:{
        true_name: [{ trigger: "blur", validator: validateName }],
        account: [{ trigger: "blur", validator: validateEmail }],
        password: [{ trigger: "blur", validator: validatePassword }]
      },
      editLoading: false,
      user_num: null,
      searchAccList: [],
      deleteId: '',
      deleteIn: '',
      deleteDialog: false
    };
  },
  computed:{
    ...mapGetters(['clientData','isRefreshData'])
  },
  watch:{
    async isRefreshData(newValue){
      if(newValue){
        await this.getSubAccList()
        this.$store.commit('index/SET_ISREFRESH',false)
        this.$message({
          type: 'success',
          message: '数据刷新成功'
        })
      }
    }
  },
  created(){
    this.getSubAccList()
    this.getuserDetail()
  },
  methods: {
    async getuserDetail(){
      let {result} = await userDetail()
      let data = rsaDecrypt(result)
      this.user_num = data.user_num
    },
    randomPass(){
      this.addForm.password = randomPassword(8)
    },
    querySearch(queryString, cb) {
      var searchAccList = this.searchAccList;
      var results = queryString ? searchAccList.filter(this.createFilter(queryString)) : searchAccList;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return (searchAccList) => {
        return (searchAccList.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },
    handleSelect(item) {
      console.log(item);
    },
    search(form){
      if(form.true_name === '' && form.account === ''){
        this.getSubAccList()
      }else{
        let fuse = new Fuse(this.tableData, {
          shouldSort: true,
          threshold: 0.4,
          location: 0,
          distance: 100,
          maxPatternLength: 32,
          minMatchCharLength: 1,
          keys: ['true_name','account']
        });
        this.tableData = fuse.search({
          $or: [{ true_name: form.true_name }, { account: form.account }]
        }).map(item => {
          return item.item
        })
      }
    },
    resetForm(form){
      this.getSubAccList()
      Object.keys(form).forEach(item => {
        form[item] = ''
      })
    },
    getSubAccList(){
      this.loadingShow = true
      return new Promise((resolve,reject) => {
        this.$store.dispatch('index/getSubAccList').then(data => {
          this.tableData = data.lists
          data.lists.forEach(item => {
            let obj = {
              value: item.account
            }
            this.searchAccList.push(obj)
          })
          this.loadingShow = false
          resolve(data)
        }).catch(err => {
          this.loadingShow = false
          reject(err)
        })

      })
    },
    async deleteInfo() {
      try{
        let requestData = this.$rsaEncryptForm({id:this.deleteId}, "SIJa%$%3hu12hu(*1278")
        let res = await subaccDelete(requestData)
        this.$message({
          message: res.msg,
          type: 'success'
        })
        this.deleteDialog = false
        this.getSubAccList()
      }catch(err){
        console.log(err)
      }
    },
    edit(row) {
      this.editDialog = true;
      Object.keys(this.editForm).forEach(item => {
        if(item == 'password'){
          this.editForm.password = ''
        }else{
          this.editForm[item] = row[item]
        }
      })
    },
    submitEdit(){
      this.$refs.editForm.validate(async (valid) => {
        if(valid){
          try{
            this.editLoading = true
            let requestData = this.$rsaEncryptForm(this.editForm, "SIJa%$%3hu12hu(*1278")
            let res = await subaccEdit(requestData)
            this.$message({
              message: res.msg,
              type: 'success'
            })
            this.editDialog = false;
            this.editLoading = false
            this.getSubAccList()
          }catch(err){
            console.log(err)
            this.editLoading = false
          }
        }
      })
    },
    createUser() {
      this.$refs.addForm.validate(async (valid) => {
        if(valid){
          try{
            this.editLoading = true
            let requestData = this.$rsaEncryptForm(this.addForm, "SIJa%$%3hu12hu(*1278")
            let res = await subaccAdd(requestData)
            this.$message({
              message: res.msg,
              type: 'success'
            })
            this.addDialog = false;
            this.editLoading = false
            this.getSubAccList()
          }catch(err){
            console.log(err)
            this.editLoading = false
          }
        }
      })
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../assets/styles/common.scss';

svg {
  font-size: 18px;
  margin-right: 20px;
  color: #ff6e00;
  cursor: pointer;
}


::v-deep .el-dialog {
  border-radius: 10px;
  .el-dialog__header {
    padding: 54px 20px 0px 70px;
  }
  .el-dialog__body {
    padding: 0 70px 60px 70px;
    color: #606266;
    font-size: 14px;
    word-break: break-all;
    p {
      margin-bottom: 36px;
    }
  }
}
.freed-title{
  font-size: 20px;
  color: #ff6e00;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
  i{
    padding-right: 10px;
  }
}

</style>