export function randomPassword(size) {
    var seed = new Array('A','B','C','D','E','F','G','H','I','J','K','L','M','N','P','Q','R','S','T','U','V','W','X','Y','Z',
'a','b','c','d','e','f','g','h','i','j','k','m','n','p','Q','r','s','t','u','v','w','x','y','z','0','1',
'2','3','4','5','6','7','8','9'
);//数组
    var seedlength = seed.length;//数组长度
    var createPassword = '';
    for (let i = 0; i < size; i++) {
        var a = Math.floor(Math.random() * seedlength);
        createPassword += seed[a];
    }
    return createPassword;
}

export function processBrowserDir(arr, type) {
    let result = arr.map((item, index) => {
        let obj = {}
        obj['label'] = item
        obj['value'] = item
        return obj
    })
    return result
}

export function getRandomMac() {
    let constArr = [
        'CC-F4-11',
        '04-33-C2',
        'C8-03-F5',
        '18-F1-D8',
        'E4-43-4B',
        '50-1D-93',
        '28-16-A8',
        'B4-6B-FC',
        'B4-86-55',
        '00-C3-F4',
    ]
    let arrIndex = Math.floor(Math.random() * constArr.length)
    let mac = `${constArr[arrIndex]}-XX-XX-XX`.replace(/X/g, function () {
        return "0123456789ABCDEF".charAt(Math.floor(Math.random() * 16))
    });
    return mac
}

export function getRandomDeviceName() {
    var uuid = 'DESKTOP-xxxxxxxx'.replace(/[xy]/g, function () {
        return "0123456789ABCDEFGHIJKLMNPQRSTUVWXYZ".charAt(Math.floor(Math.random() * 16))
    });
    return uuid;
}

export function randomIp(){
    return Array(4).fill(0).map((_, i) => Math.floor(Math.random() * 255) + (i === 0 ? 1 : 0)).join('.')
}

export function deepClone(source) {
    if (!source && typeof source !== 'object') {
      throw new Error('error arguments', 'deepClone')
    }
    const targetObj = source.constructor === Array ? [] : {}
    Object.keys(source).forEach(keys => {
      if (source[keys] && typeof source[keys] === 'object') {
        targetObj[keys] = deepClone(source[keys])
      } else {
        targetObj[keys] = source[keys]
      }
    })
    return targetObj
  }